<template>
    <div class="card border-0" v-if="selectedAssignment">
        <div class="card-body pl-0">
            <h5 class="mt-2 mb-3 clearfix"><span class="pt-1">Reasignar Asignación #{{selectedAssignment.id}}</span>
                <div class="float-right">
                    <b-button size="sm" variant="light" :to="`/assignments/view/${selectedAssignment.id}`">
                        <b-icon icon="arrow-left"></b-icon>
                    </b-button>
                </div>
            </h5>
            <b-alert variant="danger" v-if="selectedAssignment.recarga.estado == RECARGA_EJECUTADA" show>Esta asignación no puede reasignarse porque ya esta ejecutada.</b-alert>
            <div class="d-flex justify-content-center" style="margin-top:100px" v-if="loader">
                <b-spinner variant="info"></b-spinner>
            </div>
            <div v-else>
                <form @submit.prevent="reasign" v-if="usuarios.length > 0">
                    <table class="table table-sm">
                        <tr class="bg-light">
                            <th></th>
                            <th>Usuarios Conectados</th>
                        </tr>
                        <tbody class="border-bottom">
                            <tr v-for="(usuario, i) in usuarios" :key="i">
                                <td><b-form-radio v-model="selected" name="usuario" :value="usuario.id"></b-form-radio></td>
                                <td><span class="text-capitalize">{{ usuario.nombres + ' ' + usuario.apellidos }} </span>
                                    <div class="badge badge-info float-right" :id="usuario.nombres">{{usuario.asignaciones.length > 0 ? usuario.asignaciones[0].total : 0}}</div>
                                        <b-tooltip :target="usuario.nombres" triggers="hover">
                                            Asignaciones Pendientes
                                        </b-tooltip>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <b-button :disabled="selectedAssignment.recarga.estado == RECARGA_EJECUTADA" variant="primary" type="submit" class="float-right">Reasignar</b-button>
                    <b-button variant="danger" type="button" class="float-right mr-2" :to="`/assignments/view/${selectedAssignment.id}`">Cancelar</b-button>
                </form>
                <div v-if="usuarios.length == 0" class="alert alert-warning">
                    <p class="mb-0">No hay usuarios operadores para realizar las recargas.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {RECARGA_EJECUTADA} from '../../bootstrap';

import axios from 'axios'

    export default {
        data: function (){
            return {
                RECARGA_EJECUTADA,
                loader: false,
                usuarios: [],
                selected: 0
            }
        },
        created: function (){
            this.loader = true;
            axios.get('usuarios/').
            then((data) => {
                this.usuarios = data.data.data;
            }).
            catch(error => {
                error.response.data.message
            }).
            finally(() => {
                this.loader = false;
            });   
        },
        computed: {
            ...mapState('assignments', ['selectedAssignment']),
        },
        methods: {
            ...mapMutations(['addSuccessNotification', 'addErrorNotification']),
            reasign: function () {
                this.loader = true;
                let assignment = JSON.parse( JSON.stringify( this.selectedAssignment ) );
                delete assignment.recarga;
                assignment.usuario_id = this.selected;
                axios.post('asignaciones/edit/' + assignment.id, assignment).
                then((data) => {
                    if(data.data.success){
                        this.addSuccessNotification(data.data.message)
                        this.$router.push('/assignments')
                    } else {
                        this.addErrorNotification(data.data.message);
                    }
                }).
                catch(error => {
                    this.addErrorNotification(error.response.data.message);
                }).
                finally(() => {
                     this.loader = false;
                }); 
            }
        }
    }
</script>