<template>
    <div>
        <div v-if="message" class="alert" :class="[success ? 'alert-success' : 'alert-danger']">
            <p class="mb-0" v-text="message"></p>
        </div>
        <div class="bg-light border p-3 text-center" v-if="loader">
            <b-spinner style="width: 3rem; height: 3rem;" class="mb-3"></b-spinner>
            <p class="mb-0">Espere Por Favor</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
    export default {
        data: function () {
            return {
                message:'',
                success: false,
                loader: false
            }
        },
        methods: {
            ...mapActions('users', ['get'])
        },
        mounted: function () {
            this.loader = true;
            const id = this.$route.query.id;
            const token = this.$route.query.token;
            axios.post('usuarios/check-email/', {
                id: id, 
                token: token
            }).
            then((data) => {
                this.success = data.data.success;
                this.message = data.data.message;
                if(this.success){
                    this.get();
                }
            }).
            catch(error => {
                this.success = false;
                this.message = error.response.data.message;
            }).
            finally(() => {
                this.loader = false;
            });
        }
    }
</script>