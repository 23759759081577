<template>
    <b-badge :variant="value[rechargeStatus]" v-text="text[rechargeStatus]"></b-badge>
</template>

<script>
    export default {
        data: function () {
            return {
                value: {
                    'pendiente' : 'primary',
                    'procesando' : 'info',
                    'pendiente_por_corregir' : 'warning',
                    'ejecutada' : 'secondary',
                    'suspendida' : 'danger',
                    'en_espera' : 'success',
                },
                text: {
                    'pendiente' : 'Pendiente',
                    'procesando' : 'En Proceso',
                    'pendiente_por_corregir' : 'En Correción',
                    'ejecutada' : 'Ejecutada',
                    'suspendida' : 'Suspendida',
                    'en_espera' : 'En Espera',
                }
            }
        },
        props: {
            rechargeStatus: {
                type: String,
                require: true
            }
        }
    }
</script>