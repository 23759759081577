<template>
    <div class="card">
         <div class="card-header">
            Estado de conexión
            <b-spinner small v-if="loader" class="ml-2"></b-spinner>
         </div>
        <div class="card-body">
            <form>
                <div class="custom-control custom-switch mb-3 border-bottom pb-3">
                    <input type="checkbox" class="custom-control-input" v-model="estado" @change="changeState" id="customSwitch1">
                    <label class="custom-control-label" for="customSwitch1">{{ estado ? "Conectado": "Desconectado" }}</label>
                </div>
                <p><small class="text-muted">El estado de conexión indica si se le considerará al momento de asignar recargas para su ejecución.</small></p>
                <p class="mb-0"><small class="text-muted">Si su estado es conectado, el sistema lo considerará para asignaciones de recargas, en caso de estar desconectado el sistema lo omitirá cuando realice el proceso de asignación.</small></p>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapMutations, mapState } from 'vuex'

    export default {
        data: function () {
            return {
                estado: false,
                loader: false
            }
        },
        watch: {
            data: function (user) {
                this.estado = user.conectado;
            }
        },
        created: function () {
            this.estado = this.data.conectado;
        },
        computed: {
            ...mapState('users', ['data'])
        },
        methods: {
            ...mapMutations('users', ['setData', 'setConnect']),
            ...mapMutations(['addSuccessNotification', 'addErrorNotification']),
            changeState: function () {
                this.loader = true;
                axios.post('usuarios/edit/?action=connect', {'conectado': this.estado}).
                then((data) => {
                    if(data.data.success){
                        this.addSuccessNotification(data.data.message);
                        this.setConnect(this.estado);
                    } else {
                        this.addErrorNotification(data.data.message);
                    }
                }).
                catch(error => {
                    this.addErrorNotification(error.response.data.message)
                }).
                finally(() => {
                    this.loader = false;
                });
            }
        }
    }
</script>

<style scoped>
    .custom-control-label {
        font-weight: bold;
    }
</style>