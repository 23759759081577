<template>
  <div class="row mt-5">
    <div class="col-12 col-lg-5 mx-auto">
      <b-alert
        :variant="variant"
        show
        style="display: none"
        :style="[hasMessage ? { display: 'block' } : { display: 'none' }]"
        >{{ message }}
    </b-alert>
      <div class="card">
        <!-- Forgot -->
        <article class="card-body">
          <section v-if="showForm">
            <h4 class="card-title text-center mb-4 mt-1">
              Recuperar contraseña
            </h4>
            <hr />
            <p class="text-success text-center">
              Las contraseñas deben coincidir y tener un minimo de 4 caracteres.
            </p>
            <form @submit.prevent="reset">
              <input type="hidden" v-model="id" />
              <input type="hidden" v-model="token" />
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <b-icon-lock-fill></b-icon-lock-fill>
                    </span>
                  </div>
                  <input
                    class="form-control"
                    placeholder="Contraseña"
                    type="password"
                    v-model.trim="password"
                  />
                </div>
                <!-- input-group.// -->
                <label
                  v-if="errorMessages.contrasena.length > 0"
                  v-text="errorMessages.contrasena"
                  class="text-danger"
                ></label>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <b-icon-lock-fill></b-icon-lock-fill>
                    </span>
                  </div>
                  <input
                    class="form-control"
                    placeholder="Confirmación de contraseña"
                    type="password"
                    v-model.trim="passwordConfirm"
                  />
                </div>
                <!-- input-group.// -->
                <label
                  v-if="errorMessages.confirmar_contrasena.length > 0"
                  v-text="errorMessages.confirmar_contrasena"
                  class="text-danger"
                ></label>
              </div>
              <!-- form-group// -->
              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-primary btn-block"
                  :disabled="loader || !correctPasswords"
                >
                  <b-spinner small v-if="loader"></b-spinner>
                  Cambiar contraseña
                </button>
              </div>
              <!-- form-group// -->
            </form>
          </section>
          <p class="text-center mb-0" v-if="!showForm">
            <router-link to="/">Iniciar Sessión</router-link>
          </p>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: function () {
    return {
      id: "",
      token: "",
      password: "",
      passwordConfirm: "",
      errorMessages: {
        contrasena: "",
        confirmar_contrasena: "",
      },
      message: "",
      loader: false,
      variant: "success",
      showForm: true,
    };
  },
  computed: {
    hasMessage: function () {
      return this.message.length > 0;
    },
    correctPasswords: function () {
      return (
        this.password.length >= 4 &&
        this.passwordConfirm.length >= 4 &&
        this.password.trim() === this.passwordConfirm.trim()
      );
    },
  },
  mounted: function () {
    this.id = this.$route.query.id;
    this.token = this.$route.query.token;
  },
  methods: {
    reset: function () {
      this.loader = true;
      Object.entries(this.errorMessages).forEach(([key]) => {
        this.errorMessages[key] = "";
      });
      axios
        .post("usuarios/reset", {
          id: this.id,
          token: this.token,
          contrasena: this.password,
          confirmar_contrasena: this.passwordConfirm,
        })
        .then(({ data }) => {
          if (data.success) {
            this.variant = "success";
            this.showForm = false;
            this.message = data.message;
          } else {
            this.variant = "danger";
            Object.entries(data.errors).forEach(([key, value]) => {
              this.errorMessages[key] = Object.values(value)[0];
            });
            this.message = data.message;
          }
        })
        .catch((error) => {
          this.variant = "danger";
          if (!error.response) {
            this.message = "Error: Network Error";
          } else {
            this.message = error.response.data.message;
          }
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },
};
</script>