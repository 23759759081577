<template>
    <div class="card">
         <div class="card-header">
            Cambio de correo
            
         </div>
        <div class="card-body">
            <div v-if="confirmNewEmail" class="alert alert-info mb-3">
                <p class="mb-0">Por favor confirme su nuevo correo.</p>
            </div>
            <form @submit.prevent="changeEmail">
                <p class="text-muted">
                    <small>Para cambiar su correo actual, usted debe ingresar su nuevo correo y realizar la solicitud.</small>
                </p>
                <p class="text-muted">
                    <small>Nosotros le enviaremos un mail de verificacion a su nuevo correo. Para que el cambio se haga efectivo, usted debe ingresar a la bandeja de entrada de su nuevo correo y presionar el boton de verificar correo.</small>
                </p>
                <div class="border bg-light p-3 mb-3">
                    <ul class="list-unstyled mb-0">
                        <li><b>Correo Actual:</b></li>
                        <li v-text="this.data.correo"></li>
                    </ul>
                </div>
                <div class="form-group mb-4">
                    <label for="nombres">Ingrese Nuevo Correo</label>
                    <input type="text" class="form-control" v-model.trim="newEmail">
                    <small v-if="errorsMessages.new_email" class="form-text text-danger" v-text="errorsMessages.new_email"></small>
                </div>
                <button type="submit" class="btn btn-primary" :disabled="loader">
                    <b-spinner small v-if="loader"></b-spinner> Cambiar Correo
                </button>
            </form>
        </div>
    </div>
</template>

<script>
const regEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
import axios from 'axios';
import { mapMutations, mapState } from 'vuex'

    export default {
        data: function () {
            return {
                loader: false,
                newEmail: '',
                errorsMessages: {
                    new_email: ''
                }
            }
        },
        methods: {
            ...mapMutations(['addSuccessNotification', 'addErrorNotification']),
            ...mapMutations('users',['setNewEmail']),
            changeEmail: function(){
                Object.entries(this.errorsMessages).forEach(([key]) => {
                    this.errorsMessages[key] = "";
                });
                if(this.validateEmail()){
                    this.loader = true;
                    axios.post('usuarios/edit/?action=new_email', {'new_email': this.newEmail}).
                    then((data) => {
                        if(data.data.success){
                            this.addSuccessNotification(data.data.message);
                            this.setNewEmail(this.newEmail);
                        } else {
                            this.addErrorNotification(data.data.message);
                            Object.entries(data.data.errors).forEach(([key, value]) => {
                                this.errorsMessages[key] = Object.values(value)[0];
                            });
                        }
                    }).
                    catch(error => {
                        this.addErrorNotification(error.response.data.message)
                    }).
                    finally(() => {
                        this.loader = false;
                    });
                }
            },
            validateEmail: function () {
                this.errorsMessages.new_email = '';
                if(regEmail.test(this.newEmail)){
                    return true
                } else {
                    this.errorsMessages.new_email = 'Email invalido.';
                }
                return false
            }
        }, 
        created: function () {
            this.newEmail = this.data.new_email;
        },
        computed: {
            ...mapState('users', ['data']),
            confirmNewEmail: function () {
                return this.data.new_email;
            }

        }
    }
</script>