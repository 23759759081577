<template>
  <div class="row mt-5">
    <div class="col-12 col-lg-5 mx-auto">
      <b-alert
        :variant="variant"
        show
        style="display: none"
        :style="[hasMessage ? { display: 'block' } : { display: 'none' }]"
        >{{ message }}</b-alert
      >
      <div class="card">
        <!-- Forgot -->
        <article class="card-body">
          <h4 class="card-title text-center mb-4 mt-1">Recuperar contraseña</h4>
          <hr />
          <p class="text-success text-center">
            Ingresa tu correo para enviarte las instrucciones de recuperación de
            contraseña
          </p>
          <form @submit.prevent="forgot">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <b-icon-person-fill></b-icon-person-fill>
                  </span>
                </div>
                <input
                  name=""
                  class="form-control"
                  placeholder="Email"
                  type="email"
                  v-model="email"
                />
              </div>
              <!-- input-group.// -->
            </div>
            <!-- form-group// -->
            <div class="form-group">
              <button
                type="submit"
                class="btn btn-primary btn-block"
                :disabled="loaderForgot || !isValidEmail"
              >
                <b-spinner small v-if="loaderForgot"></b-spinner>
                Recuperar contraseña
              </button>
            </div>
            <!-- form-group// -->
            <p class="text-center">
                <router-link to="/">Iniciar Sesión</router-link>
            </p>
          </form>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
const regEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
    data: function(){
        return {
            loaderForgot: false,
            email: '',
            variant: 'success',
            message: '',
        }
    },
    computed: {
        hasMessage: function () {
            return this.message.length > 0;
        },
        isValidEmail: function () {
            return regEx.test(this.email);
        }
    },
    methods: {
        forgot: function(){
          this.loaderForgot = true;
          axios.post('usuarios/forgot', {
            correo: this.email
          }).
            then(({data}) => {
                if(data.success){
                    this.variant = 'success';
                    this.email = '';
                } else {
                    this.variant = 'danger';
                }
                this.message = data.message;
            }).
            catch(error  => {
                 this.variant = 'danger';
                if(!error.response){
                    this.message = 'Error: Network Error';
                } else {
                    this.message = error.response.data.message;
                }
            })
            .finally(() => {
              this.loaderForgot = false;
            })
        }
    }
};
</script>