<template>
    <div>
        <div class="d-flex justify-content-center" style="padding-top:100px" v-if="assignmentLoader">
            <b-spinner variant="info"></b-spinner>
        </div>
        <div v-else class="pr-3">
            <h5 class="mt-3 mb-3 clearfix">Actualizar</h5>
            <form v-if="selectedAssignment" @submit.prevent="updateAssignment">
                <div class="form-gropu">
                    <b-form-select :options="states" class="mb-3" v-model="newStatus"></b-form-select>
                    <small v-if="errorsUpdate.estado" class="form-text text-danger" v-text="errorsUpdate.estado"></small>
                </div>

                <div v-if="newStatus == 'ejecutada'">
                    <div class="form-group">
                        <label>Donde se ejecuto la recarga</label>
                        <b-form-select :options="platformList" v-model="selectedPlatform"></b-form-select>
                        <small v-if="errorsUpdate.plataforma_id" class="form-text text-danger" v-text="errorsUpdate.plataforma_id"></small>
                    </div>

                    <div class="form-group">
                        <label for="referencia">Referencia</label>
                        <b-form-input type="text" id="referencia" v-model="referencia"></b-form-input>
                        <small v-if="errorsUpdate.referencia" class="form-text text-danger" v-text="errorsUpdate.referencia"></small>
                    </div>
                </div>

                <div class="form-group">
                    <label for="referencia">Mensaje para el usuario</label>
                    <b-form-select :options="messagesFeedbacks"  v-model="feedbackID"></b-form-select>
                    <small v-if="errorsUpdate.feedback_id" class="form-text text-danger" v-text="errorsUpdate.feedback_id"></small>
                </div>

                <div class="row">
                    <div class="col-md-6 mb-3">
                        <b-button :to="`/assignments/view/${selectedAssignment.id}`" type="button" variant="danger" class="btn-block">Cancelar</b-button>
                    </div>
                    <div class="col-md-6 mb-3">
                        <b-button variant="primary" type="submit" class="btn-block">Actualizar</b-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
    FEEDBACK_RECARGA_PENDIENTE_POR_CORREGIR_EDITADA_POR_OPERADOR_MONTO_INVALIDO,
    FEEDBACK_RECARGA_PENDIENTE_POR_CORREGIR_EDITADA_POR_OPERADOR_TELEFONO_SUSPENDIDO_O_POSTPAGO,
    FEEDBACK_RECARGA_PENDIENTE_POR_CORREGIR_EDITADA_POR_OPERADOR_NUMERO_INVALIDO
} from '../../bootstrap'

    export default {
        data: function () {
            return {
                newStatus: 'ejecutada',
                selectedPlatform: '',
                referencia: '',
                feedbackID: null,
                recarga: {}
            }
        },
        mounted: function () {
            if(this.feedbackMessageDone){
                this.feedbackID = this.feedbackMessageDone.id;
            }
        },
        watch: {
            newStatus: function (newVal) {
                if(newVal == 'ejecutada'){
                    this.feedbackID = this.feedbackMessageDone.id;
                } else {
                    this.feedbackID = null;
                }
            }
        },
        computed: {
            ...mapState('assignments', [
                'selectedAssignment', 
                'assignmentLoader', 
                'plataforms', 
                'feedbackMessages', 
                'errorsUpdate'
            ]),
            states: function(){
                return [
                    { value: 'ejecutada',  text: 'Marcar Como Ejecutada',},
                    { value: 'pendiente_por_corregir', text: 'Corregir'}
                ]
            },
            platformList: function () {
                let list = [];
                if(this.plataforms.length > 0){
                    for (let i = 0; i < this.plataforms.length; i++) {
                        list.push({'text': this.plataforms[i].nombre, 'value': this.plataforms[i].id})
                    }
                }
                return list;
            },
            messagesFeedbacks: function() {
                let feedbacks = [];
                    if(this.newStatus == 'ejecutada'){
                        feedbacks.push({'text': 'Ejecutada', 'value': this.feedbackMessageDone.id});
                    } else {
                        feedbacks.push({'text': 'Seleccione un mensaje para el usuario', 'value': null});
                        let toCorrect = this.feedbackMessages.filter((feedback) => {
                            let messagesMoment = [
                                FEEDBACK_RECARGA_PENDIENTE_POR_CORREGIR_EDITADA_POR_OPERADOR_MONTO_INVALIDO,
                                FEEDBACK_RECARGA_PENDIENTE_POR_CORREGIR_EDITADA_POR_OPERADOR_TELEFONO_SUSPENDIDO_O_POSTPAGO,
                                FEEDBACK_RECARGA_PENDIENTE_POR_CORREGIR_EDITADA_POR_OPERADOR_NUMERO_INVALIDO
                            ];
                            return messagesMoment.includes( feedback.moment );
                        });
                        for (let i = 0; i < toCorrect.length; i++) {
                            feedbacks.push({'text': toCorrect[i].comentario, 'value': toCorrect[i].id})
                        }
                    }
                return feedbacks;
            },
            feedbackMessageDone: function () {
                return this.feedbackMessages.find( element => {
                    return element.moment == "recarga_ejecutada";
                })
            }
        },
        methods: {
            ...mapActions('assignments', [
                'updateRecharge'
            ]),
            updateAssignment: function () {
                this.assignment = JSON.parse( JSON.stringify( this.selectedAssignment ) )
                this.assignment.recarga.estado = this.newStatus;
                this.assignment.recarga.feedback_id = this.feedbackID;

                if(this.assignment.recarga.estado == 'ejecutada'){
                    this.assignment.recarga.referencia = this.referencia;
                    this.assignment.recarga.plataforma_id = this.selectedPlatform;
                }

                delete this.assignment.recarga.plataforma;
                delete this.assignment.recarga.orden;
                delete this.assignment.recarga.tasa;
                delete this.assignment.recarga.servicio;
                delete this.assignment.recarga.feedback
                this.updateRecharge(this.assignment);
            }
        }
    }
</script>

