<template>
    <div style='height: 535px'>
        <div class="d-flex justify-content-center" style="padding-top:100px" v-if="assignmentLoader">
            <b-spinner variant="info"></b-spinner>
        </div>
        <div v-else style="overflow-y:auto; height:100%" id="content-assignment">
            <div class="card border-0" v-if="selectedAssignment">
                <div class="card-body pt-3 pb-3 pr-3 pl-0">
                    <h5 class="mt-2 mb-3 clearfix"><span class="pt-1">Asignación #{{selectedAssignment.id}}</span>
                        <div class="float-right">
                            <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none p-0" no-caret>
                                <template #button-content>
                                    <b-icon small icon="menu-button-wide-fill"></b-icon><span class="sr-only">Search</span>
                                </template>
                                <b-dropdown-item :to="`/assignments/reasign/${$route.params.id}`">Reasignar</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </h5>
                    <div class="bg-light py-3 rounded mb-3">
                        <p class="text-center mb-1"><strong>{{selectedAssignment.recarga.servicio.nombre}}</strong></p>
                        <p class="mb-0 text-center" style="font-size:36px">
                            <img :src="selectedAssignment.recarga.servicio.imagen" width="80" class="rounded img-thumbnail"> 
                            {{selectedAssignment.recarga.numero}}
                        </p>
                        <p class="mb-0 display-5 text-center" style="font-size:18px">Saldo: 
                            <span class="text-danger" v-if="selectedAssignment.recarga.estado == RECARGA_PROCESANDO">{{ selectedAssignment.recarga.saldo | currency('Bs. D.') }}</span>
                            <span class="text-danger" v-else >******</span>
                        </p>
                        <ul class="list-unstyled mx-3 mt-4 mb-0">
                            <li>Asignada: <span class="float-right">{{ selectedAssignment.hora | dateFormat }}</span></li>
                            <li>Creada: <span class="float-right">{{ selectedAssignment.recarga.creada | dateFormat }}</span></li>
                            <li>Estado: 
                                <span class="float-right">
                                    <badges :rechargeStatus="selectedAssignment.recarga.estado"></badges>
                                    <a href="#" @click.prevent.stop="inProcess" class="ml-2" v-if="selectedAssignment.recarga.estado == RECARGA_PENDIENTE">
                                        <b-icon icon="arrow-right-circle-fill"></b-icon>
                                    </a>
                                </span>
                            </li>
                            <li>ID Recarga: <span class="float-right">#{{selectedAssignment.recarga.id}}</span></li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <b-button :to="`/assignments/update/${selectedAssignment.id}`" :variant="btnVariant" class="btn-block" :disabled="selectedAssignment.recarga.estado != RECARGA_PROCESANDO">Actualizar</b-button>
                        </div>
                    </div>
                </div>
                <div class="card-body border-0 pl-0">
                    <h5>Descripción</h5>
                    <ul class="list-unstyled">
                        <li><strong>Tasa: </strong> <span class="float-right">{{selectedAssignment.recarga.tasa.monto}}</span></li>
                        <li><strong>Última Actualización</strong><span class="float-right">{{selectedAssignment.recarga.actualizada | dateFormat}}</span></li>
                        <li><strong>Orden</strong><span class="float-right">{{'#' + selectedAssignment.recarga.orden.id}}</span></li>
                        <li><strong>Ejecutada</strong><span class="float-right">{{ fechaDeEjecucion }}</span></li>
                        <li><strong>Plataforma</strong><span class="float-right">{{plataforma}}</span></li>
                        <li><strong>Referencia</strong><span class="float-right">{{referencia}}</span></li>
                    </ul>
                    <hr />
                    <h6>Feedback</h6>
                    <p>{{ selectedAssignment.recarga.feedback.comentario }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Badges from '../../components/Badges.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import {RECARGA_PROCESANDO, RECARGA_PENDIENTE} from '../../bootstrap'

    export default {
        data: function () {
            return {
                RECARGA_PROCESANDO,
                RECARGA_PENDIENTE
            }
        },
        components: {
            Badges
        },
        watch: {
            $route(to, from) {
                this.getAssignment(this.$route.params.id);
            }
        },
        computed: {
            ...mapState('assignments', ['selectedAssignment', 'assignmentLoader']),
            ...mapGetters('assignments', ['feedbackMessageInProcess']),
            fechaDeEjecucion: function () {
                if(this.selectedAssignment.recarga.fecha_de_ejecucion){
                    return this.$options.filters.dateFormat(this.selectedAssignment.recarga.fecha_de_ejecucion);
                } else {
                    return 'No aplica.'
                }
            },
            plataforma: function () {
                if(this.selectedAssignment.recarga.plataforma){
                    return this.selectedAssignment.recarga.plataforma.nombre;
                } else {
                    return 'No aplica.'
                }
            },
            referencia: function () {
                if(this.selectedAssignment.recarga.referencia){
                    return this.selectedAssignment.recarga.referencia;
                } else {
                    return 'No aplica.'
                }
            },
            btnVariant: function(){
                return this.selectedAssignment.recarga.estado != 'procesando' ? 'secondary' : 'primary';
            }
        },
        methods: {
            ...mapActions('assignments', ['getAssignment', 'markInProcess']),
            inProcess: function(){
                let assignment = JSON.parse( JSON.stringify( this.selectedAssignment ) )
                assignment.recarga.estado = this.RECARGA_PROCESANDO
                assignment.recarga.feedback_id = this.feedbackMessageInProcess.id;
                delete assignment.recarga.plataforma;
                delete assignment.recarga.orden;
                delete assignment.recarga.tasa;
                delete assignment.recarga.servicio;
                delete assignment.recarga.feedback;
                this.markInProcess(assignment);
            }
        },
        created: function () {
            this.getAssignment(this.$route.params.id);
        },
        updated: function( ){
            for (let item of document.querySelectorAll('.table-primary')) {
                item.classList.remove('table-primary');
            }

            let select = (document.querySelector(`#table-row-${this.$route.params.id}`));
            if(select){
                document.querySelector(`#table-row-${this.$route.params.id}`).classList.add('table-primary')
            }
            if(document.querySelector('#content-assignment')){
                document.querySelector('#content-assignment').scrollTop = 0;
            }
        },
        filters: {
              currency: function (value, signed, fraction = 2){
                    let formatter = new Intl.NumberFormat('de-DE', {'minimumFractionDigits':fraction});
                    return formatter.format(value) + ' ' + signed   
              },
              dateFormat: function (value) {
                  let date = new Date(value);
                  let months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
                  return date.getDate() + ' ' + months[date.getMonth()] + '. ' + date.getFullYear() + ' ' + 
                  date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds(); 
              }
        }
    }
</script>