<template>
    <div class="row">
        <div class="col-12 mb-3">
            <h4>Perfil</h4>
        </div>
        <div class="col-md-6">
            <my-data></my-data>
        </div>
        <div class="col-md-6">
            <user-connect class="mb-4"></user-connect>
            <change-email></change-email>
        </div>
    </div>
</template>

<script>
import MyData from '../../components/Profile/MyData.vue';
import UserConnect from '../../components/Profile/UserConnect.vue'
import ChangeEmail from '../../components/Profile/ChangeEmail.vue'

    export default {
        components: {
            MyData, UserConnect, ChangeEmail
        }
    }
</script>

