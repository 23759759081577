<template>
    <div class="card">
         <div class="card-header">
             Mis Datos
         </div>
        <div class="card-body">
        <form @submit.prevent="saveProfile">
            <div class="row border-bottom mb-3 pb-3">
                <div class="col-lg-3">
                    <img :src="image" class="img-fluid img-thumbnail mx-auto d-block" :alt="profile.nombres"> 
                    <input type="file" ref="avatar" accept="image/*" class="d-none" @change="setAvatarFile($event)">
                </div>
                <div class="col-lg-9 mb-3 d-flex justify-content-center justify-content-lg-start align-items-lg-end flex-column mt-auto">
                    <p v-if="file">
                        {{file.name}}
                    </p>
                    <button type="button" class="btn btn-info mb-1 mt-2" @click="$refs.avatar.click()">
                        <b-icon-image></b-icon-image>
                    Cambiar imagen</button>                    
                </div>
                <div class="col-12">
                    <small class="form-text text-muted">La imagen debe ser 200x200 pixeles.</small>
                    <small v-if="errorsMessages.avatar_file" class="form-text text-danger" v-text="errorsMessages.avatar_file"></small>
                </div>
            </div>
            <div class="form-group mb-4">
                <label for="nombres">Nombres</label>
                <input type="text" class="form-control" v-model.trim="profile.nombres">
                <small v-if="errorsMessages.nombres" class="form-text text-danger" v-text="errorsMessages.nombres"></small>
            </div>
            <div class="form-group mb-4">
                <label for="apellidos">Apellidos</label>
                <input type="text" class="form-control" v-model.trim="profile.apellidos">
                <small v-if="errorsMessages.apellidos" class="form-text text-danger" v-text="errorsMessages.apellidos"></small>
            </div>
            <button type="submit" class="btn btn-primary">
                <b-spinner small v-if="loader"></b-spinner>Actualizar
            </button>
        </form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import axios from 'axios';

    export default {
        data: function () {
            return {
                profile: {},
                image:'',
                file: null,
                loader: false,
                imageValidate: false,
                errorsMessages: {
                    nombres:'',
                    apellidos: '',
                    avatar_file:''
                }
            }
        },
        watch: {
            '$router': {
                handler: function() {
                    this.setProfileData();
                },
                immediate: true,
            },
            data: function() {
                this.setProfileData();
            }
        },
        computed: {
            ...mapState('users', ['data'])
        },
        methods: {
            ...mapMutations('users', ['setData']),
            ...mapMutations(['addSuccessNotification', 'addErrorNotification']),
            ...mapActions('users', ['get']),
            setProfileData: function () {
                this.profile = {...this.data};
                this.image = this.profile.avatar;
                delete this.profile.avatar;
                delete this.profile.correo;
            },
            setAvatarFile: function(event){
                this.file = event.target.files[0];
            },
            saveProfile: function( ){
                this.formValidate()
            },
            formValidate: function(){
                this.loader = true;
                const pattern = /^[A-Za-z ]+$/i;
                const maxSize = 2;

                Object.entries(this.errorsMessages).forEach(([key]) => {
                    this.errorsMessages[key] = "";
                });

                if(this.profile.nombres.length < 1){
                    this.errorsMessages.nombres = 'Los nombres del usuario son obligatorios.';
                    this.loader = false;
                    return false
                }

                if(this.profile.nombres.length < 3 || this.profile.nombres.length > 30){
                    this.errorsMessages.nombres = 'Los caracteres de los nombres deben ser mayor de 3 y menor de 30.'
                    this.loader = false;
                    return false
                }

                if(!pattern.test(this.profile.nombres)){
                    this.errorsMessages.nombres = 'Solo letras y espacios son permitidos'
                    this.loader = false;
                    return false
                }

                if(this.profile.apellidos.length < 1){
                    this.errorsMessages.apellidos = 'Los apellidos del usuario son obligatorios.'
                    this.loader = false;
                    return false
                }

                if(this.profile.apellidos.length < 3 || this.profile.apellidos.length > 30){
                    this.errorsMessages.apellidos = 'Los caracteres de los apellidos deben ser mayor de 3 y menor de 30.'
                    this.loader = false;
                    return false
                }

                if(!pattern.test(this.profile.apellidos)){
                    this.errorsMessages.apellidos = 'Solo letras y espacios son permitidos'
                    this.loader = false;
                    return false
                }

                if(this.file != null){
                    if((this.file.size / 1024 / 1024) > maxSize){
                        this.errorsMessages.avatar_file = 'La imagen no debe pesar mas de '+maxSize+'MB.'
                        this.loader = false;
                        return false
                    }

                    if(!["image/jpg", "image/jpeg", "image/png", "image/gif"].includes(this.file.type)){
                        this.errorsMessages.avatar_file = 'La imagen que intenta subir, no tiene un formato valido.';
                        this.loader = false;
                        return false
                    }

                    let img = new Image()
                    img.onload = () => {
                        const width  = img.width;
                        const height = img.height; 
                        if( width != 200 || height != 200){
                            this.errorsMessages.avatar_file = 'La imagen debe ser un tamaño menor o igual 200x200 pixel';
                            this.loader = false;
                            return false
                        } else {
                            this.save();
                        }
                    }
                    img.src = window.URL.createObjectURL(this.file);
                } else {
                    this.save();
                }

                return false
            },
            save: function(){
                let formData = new FormData();
                if(this.file != null){
                    formData.append('avatar_file', this.file);
                    this.file = null;
                }
                formData.append('nombres', this.profile.nombres);
                formData.append('apellidos', this.profile.apellidos);
                        
                axios.post('usuarios/edit', formData, {
                    'Content-Type': 'multilpart/form-data'
                }).
                then((data) => {
                    if(data.data.success){
                        this.addSuccessNotification(data.data.message);
                        this.get();
                    } else {
                        this.addErrorNotification(data.data.message);
                        Object.entries(data.data.errors).forEach(([key, value]) => {
                            this.errorsMessages[key] = Object.values(value)[0];
                        });
                    }
                }).
                catch(error => {
                    this.addErrorNotification(error.response.data.message)
                }).
                finally(() => {
                    this.loader = false;
                });
            }
        }
    }
</script>
